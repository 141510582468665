import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Box, Typography, useTheme } from '@mui/material';

import BarGraph from './Bar';
import DashboardTable from './DashboardTable';
import Example from './Bar';
import React from 'react';
import SampleBar from './Bar';
import SimpleLineChart from './Bar';
import { tokens } from 'context/theme.context';

interface DataItem {
  month: string;
  productA: number;
  productB: number;
  productC: number;
}

interface Props {
  title?: string;
  data: DataItem[];
  chartHeight?: number;
  dataKeys: string[];
  barColors?: string[];
}

const StackedBarGraph: React.FC<Props> = ({ title, data, chartHeight = 200, dataKeys, barColors }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  // const tableData = [
  //   ['Combined Header', 'Count', 'Ratio', 'Count', 'Ratio', 'Count', 'Ratio'],
  //   ['Sample RHU  Population: 30,000, Household 7,500', '3', '1:10,000', '3', '1:10,000', '3', '1:10,000'],
  //   ['Sample RHU  Population: 30,000, Household 7,500', '2', '1:30,000', '5', '1:12,000', '3', '1:20,000'],
  //   ['Sample RHU Population: 15,000,Household 3,750', '2', '1:7,500', '3', '1:5,000', '15', '1:1,000'],
  // ];
  // const mockColumnData = [
  //   { title: 'Standard Per Health Workforce Per Rural Health Unit' },
  //   'Doctor Standard Ratio: 1:20,000',
  //   'Nurse Standard Ratio 1:10,000',
  //   'Midwife Standard Ratio: 1:5,000',
  // ];

  // const combinedData = {
  //   tableData: tableData,
  //   mockColumnData: mockColumnData,
  // };
  return (
    <Box>
      <Typography variant="h4" color={colors.text}>
        {title}
      </Typography>
      <ResponsiveContainer width="100%" height={chartHeight}>
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis />
          <Tooltip />
          <Legend />
          {dataKeys.map((key, index) => (
            <Bar
              key={index}
              dataKey={key}
              stackId="a"
              fill={
                barColors && barColors[index]
                  ? barColors[index]
                  : `#${Math.floor(Math.random() * 16777215).toString(16)}`
              }
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
      {/* <Box padding="50px">
        <DashboardTable combinedData={combinedData} />
      </Box> */}
      <Box padding="50px"></Box>
    </Box>
  );
};

export default StackedBarGraph;
