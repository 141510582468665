import { BarGraph, CustomIconButton, CustomLineChart, PieChartComponent } from 'components';
import { Box, Button, Modal, TextField, Typography, useTheme } from '@mui/material';
import { Draggable, Droppable } from 'react-beautiful-dnd';

import { ConfirmationDialog } from 'components';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DifferenceIcon from '@mui/icons-material/Difference';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Item from './Item';
import React from 'react';
import { styled } from '../stiches.config';
import { tokens } from 'context/theme.context';
import { useState } from 'react';

const transformRawData = (raw: any[]) => {
  const aggregateData: { [key: string]: number } = {};

  raw.forEach((item) => {
    if (!aggregateData[item.reponse]) {
      aggregateData[item.reponse] = 0;
    }
    aggregateData[item.reponse]++;
  });

  return Object.keys(aggregateData).map((key) => ({ name: key, value: aggregateData[key] }));
};
interface ColumnProps {
  list: any[];
  column_id: string;
  startEditingWidget: (widget: any) => void;
  duplicateWidget: (widget: any) => void;
  confirmDeleteWidget: (
    widget_id: number,
    column_id: string,
    setShowDeleteConfirmation: (val: boolean) => void
  ) => void;
}

const data2: any = [
  { month: 'Aklan', Konsulta: 1200, 'TB-DOTS': 800, 'PCB 1': 600 },
  { month: 'Iloilo', Konsulta: 1500, 'TB-DOTS': 700, 'PCB 1': 800 },
  { month: 'Antique', Konsulta: 800, 'TB-DOTS': 1200, 'PCB 1': 500 },
  { month: 'Capiz', Konsulta: 1000, 'TB-DOTS': 900, 'PCB 1': 700 },
  { month: 'Guimaras', Konsulta: 500, 'TB-DOTS': 800, 'PCB 1': 800 },
  { month: 'Negros Occ', Konsulta: 1100, 'TB-DOTS': 400, 'PCB 1': 1000 },
];

const lineChartData = [
  { month: 'January', value1: 100, value2: 80, value3: 120 },
  { month: 'February', value1: 110, value2: 85, value3: 130 },
  { month: 'March', value1: 105, value2: 90, value3: 140 },
  { month: 'April', value1: 115, value2: 75, value3: 150 },
  { month: 'May', value1: 120, value2: 82, value3: 160 },
  { month: 'June', value1: 125, value2: 78, value3: 170 },
];

const Column: React.FC<ColumnProps> = ({
  list,
  column_id,
  startEditingWidget,
  duplicateWidget,
  confirmDeleteWidget,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [widgetToDelete, setWidgetToDelete] = useState<number>(0);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [editedTitle, setEditedTitle] = useState<string>('');

  const handleShowDeleteConfirmation = (widget_id: number) => {
    setWidgetToDelete(widget_id);
    setShowDeleteConfirmation(true);
  };

  const handleConfirmDelete = async () => {
    confirmDeleteWidget(widgetToDelete, column_id, setShowDeleteConfirmation);
  };

  const StyledList = styled('div', {
    border: '1px dashed ' + colors.dark,
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    gap: '20px',
  });
  const handleEditClick = (widget: any) => {
    startEditingWidget(widget);
  };

  const getComponent = (widget: any, index: number, isDragging: boolean) => {
    const wrappedComponent = (actualComponent: JSX.Element) => (
      <Box
        sx={{
          backgroundColor: isDragging ? colors.border : colors.secondary_background,
          borderRadius: '8px',
          padding: '8px',
          transition: 'background-color .2s ease-out',
          '&:hover': {
            backgroundColor: colors.border,
            transition: 'background-color .2s ease-in',
          },
        }}
      >
        <Box display="flex" justifyContent="flex-end">
          <CustomIconButton icon={<DifferenceIcon />} onClick={() => duplicateWidget(widget)} tooltip="Duplicate" />
          <CustomIconButton icon={<EditOutlinedIcon />} onClick={() => handleEditClick(widget)} tooltip="Edit" />
          <CustomIconButton
            icon={<DeleteOutlinedIcon />}
            onClick={() => handleShowDeleteConfirmation(widget.id)}
            color="error"
            tooltip="Delete"
          />
        </Box>
        <Box
          style={{
            backgroundColor: colors.secondary_background,
            borderRadius: 8,
            padding: '10px',
          }}
        >
          <Box>
            <Typography variant="h5" fontWeight="600" color={colors.text}>
              {widget.title}
            </Typography>

            <Box>{actualComponent}</Box>
          </Box>
        </Box>
      </Box>
    );

    switch (widget.type) {
      case 'empty-container':
      case 'scorecard':
        return wrappedComponent(
          <div style={{ height: '185px' }}>
            <p>287</p>
          </div>
        );
      case 'pie':
        const transformedData = transformRawData([
          {
            facility: '1',
            reponse: 'A',
          },
          {
            facility: '2',
            reponse: 'B',
          },
          {
            facility: '3',
            reponse: 'C',
          },
          {
            facility: '4',
            reponse: 'C',
          },
        ]);
        return wrappedComponent(<PieChartComponent data={transformedData} />);

      case 'bar':
        return wrappedComponent(<BarGraph key={index} data={data2} />);
      case 'line':
        return wrappedComponent(
          <CustomLineChart
            key={index}
            data={lineChartData}
            dataKeys={[
              { dataKey: 'value1', stroke: '#8884d8' },
              { dataKey: 'value2', stroke: '#82ca9d' },
              { dataKey: 'value3', stroke: '#ffc658' },
            ]}
            xAxisKey="month"
            yAxisLabel="Values"
          />
        );
      default:
        return wrappedComponent(<Item key={widget.type} text={widget.title} index={index} />);
    }
  };

  return (
    <Droppable droppableId={column_id}>
      {(provided) => (
        <Box display="flex">
          {list && (
            <StyledList {...provided.droppableProps} ref={provided.innerRef}>
              {list.map((widget, index) => {
                return (
                  <Draggable key={widget.id} draggableId={(widget.id ?? index).toString()} index={index}>
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        {getComponent(widget, index, snapshot.isDragging)}
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </StyledList>
          )}
          <ConfirmationDialog
            open={showDeleteConfirmation}
            setOpen={setShowDeleteConfirmation}
            message="Are you sure you want to delete this widget?"
            onConfirm={handleConfirmDelete}
            cancelButtonLabel="No"
            confirmButtonLabel="Yes"
          />
          <Modal open={isEditModalOpen} onClose={() => setIsEditModalOpen(false)}>
            <Box>
              <TextField label="Title" value={editedTitle} onChange={(e) => setEditedTitle(e.target.value)} />

              <Button
                onClick={() => {
                  setIsEditModalOpen(false);
                }}
              >
                Save
              </Button>
              <Button onClick={() => setIsEditModalOpen(false)}>Cancel</Button>
            </Box>
          </Modal>
        </Box>
      )}
    </Droppable>
  );
};

export default Column;
