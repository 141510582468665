import { formatNumber } from 'utils';

export interface XAxisDataKey {
  key: string;
  displayName?: string;
  type?: string | 'number';
  renderValue?: (row: any) => any;
  formatValue?: (value: string) => any;
}

export interface YAxisDataKey {
  key: string;
  displayName?: string;
  chartColor: string;
  sourceFields?: string[];
}

export interface YAxisBreakdownDataKey {
  key: string;
  chartColor: string;
  displayName?: string;
}

export interface ChartLabel {
  xAxisLabel?: string;
  yAxisLabel?: string;
}

export interface TableColumn {
  key: string;
  displayName?: string;
  type?: string | 'number';
  renderValue?: (row: any) => any;
  formatValue?: (value: string) => any;
}

export interface DashboardWidget {
  type: 'bar' | 'table';
  title: string;
  label: ChartLabel;
  containerDimnesion?: {
    colSpan?: number;
    rowSpan?: number;
  };
  xAxisDataKeys: XAxisDataKey[];
  yAxisDataKeys: YAxisDataKey[];

  aggregation?: 'sum' | 'count';
  yAxisBreakdown?: {
    breakdownAggregation?: 'sum' | 'count';
    breakdownKey: string;
    yAxisBreakdownKeys: YAxisBreakdownDataKey[];
  };
  dataPreprocessor?: (data: any[]) => any[];

  //Config
  displayAsPercentage?: boolean;
  stacked?: boolean;

  // Filters
  facilityTypeFilter?: string[];

  //Table
  tableKey?: {
    key: string;
    displayKey?: string;
    displayName?: string;
  };
  columns?: TableColumn[];
  tableSettings?: {
    visibleColumns?: string[];
    sortBy?: string;
  };
}

export interface RawDataFilter {
  field: string;
  values: string[];
}

export const getDashboardData2 = (rawData: any[], widget: DashboardWidget) => {
  // if (widget.type === 'table') {
  //   return getTableData(rawData, widget);
  // }

  return getChartData(rawData, widget);
};

export const filterRawData = (raw_data: any[], filter: RawDataFilter[]) => {
  const getFilter = (data: any) => {
    const results = filter.map((item) => item.values.some((i) => i === data[item.field]));

    return results.every((result) => result);
  };
  raw_data = raw_data.filter((data) => getFilter(data));
  return raw_data;
};

export const getChartData = (rawData: any[], widget: DashboardWidget) => {
  const {
    xAxisDataKeys,
    yAxisDataKeys,
    yAxisBreakdown,
    facilityTypeFilter,
    displayAsPercentage,
    dataPreprocessor,
    tableKey,
    tableSettings,
  } = widget;
  const { yAxisBreakdownKeys } = yAxisBreakdown ?? {};

  let dataToBeProcessed: any[] = rawData;
  if (facilityTypeFilter) {
    dataToBeProcessed = filterRawData(dataToBeProcessed, [{ field: 'Facility Type', values: facilityTypeFilter }]);
  }

  if (dataPreprocessor) {
    dataToBeProcessed = dataPreprocessor(dataToBeProcessed);
  }

  const totalResponses = dataToBeProcessed.length;
  const tableData: any = {};
  const data = xAxisDataKeys?.flatMap((dataKey) => {
    const xDataKeyString = dataKey.key;
    const xDataKeyDisplayString = dataKey.displayName ?? dataKey.key;

    const result = dataToBeProcessed.reduce((result, item) => {
      const response = item[xDataKeyString];
      const yAxisData: any = processChartData(response, item, widget, dataKey);

      if (tableKey) {
        let tableKeyString = tableKey.displayName ?? tableKey.key;
        const tableMainColumn = item[tableKey.key];
        if (tableData[tableMainColumn])
          tableData[tableMainColumn] = combineResults([
            tableData[tableMainColumn],
            { [xDataKeyDisplayString]: yAxisData[tableKeyString] },
          ]);
        else tableData[tableMainColumn] = { [xDataKeyDisplayString]: yAxisData[tableKeyString] };
      }
      return combineResults([result, yAxisData]);
    }, {});

    let extra: any = { total: totalResponses };
    if (displayAsPercentage) extra = displayResultAsPercentage(result, extra);

    return { 'chart-key': xDataKeyDisplayString, ...result, extra };
  });

  if (tableKey) {
    let tableKeyString = tableKey.displayName ?? tableKey.key;
    return processTableData(tableData, tableKeyString, tableSettings);
  }
  //   const yAxisDataKeyStrings =
  //     _multipleYAxisWithBreakdown && yAxisBreakdown
  //       ? yAxisDataKeys?.flatMap((dataKey) => {
  //           const yDataKeyDisplayString = dataKey.displayName ?? dataKey.key;
  //           return yAxisBreakdown.map((breakdown) => {
  //             const breakdownKey = breakdown.displayName ?? breakdown.key;
  //             const finalKey = yAxisDataKeys.length > 1 ? `${yDataKeyDisplayString} - ${breakdownKey}` : breakdownKey;
  //             return { dataKey: finalKey, chartColor: breakdown.chartColor };
  //           });
  //         })
  //       : yAxisDataKeys.map((yAxis) => {
  //           const finalKey = yAxis.displayName ?? yAxis.key;
  //           return { dataKey: finalKey, chartColor: yAxis.chartColor };
  //         });

  const yAxisDataKeyStrings = yAxisBreakdownKeys?.length
    ? yAxisBreakdownKeys.map((breakdown) => {
        const finalKey = breakdown.displayName ?? breakdown.key;
        return { dataKey: finalKey, chartColor: breakdown.chartColor };
      })
    : yAxisDataKeys.map((yAxis) => {
        const finalKey = yAxis.displayName ?? yAxis.key;
        return { dataKey: finalKey, chartColor: yAxis.chartColor };
      });

  return {
    data,
    dataKeys: yAxisDataKeyStrings.map((item) => item.dataKey),
    chartColors: yAxisDataKeyStrings.reduce((result, item) => ({ ...result, [item.dataKey]: item.chartColor }), {}),
  };
};

const processTableData = (tableData: any, tableKey: string, tableSettings: { sortBy?: string } | undefined) => {
  const data = Object.keys(tableData).map((key) => {
    return { [tableKey]: key, ...tableData[key] };
  });

  const sortBy = tableSettings?.sortBy ?? tableKey;
  data.sort((a, b) => {
    const nameA = a[sortBy].toUpperCase();
    const nameB = b[sortBy].toUpperCase();

    if (nameA < nameB) {
      return -1;
    }
    return 0;
  });
  return { data, dataKeys: [], chartColors: {} };
};

const displayResultAsPercentage = (result: any, extra: any) => {
  for (let key in result) {
    if (typeof result[key] === 'number') {
      const percent = extra.total > 0 ? formatNumber((result[key] / extra.total) * 100, 0) : 0;
      extra = { ...extra, [key]: result[key] };
      result[key] = percent;
    }
  }
  return extra;
};

// UTILS
// const multipleYAxisWithBreakdown = (widget: DashboardWidget) => {
//   return !!(widget.yAxisDataKeys.length > 1 && widget.yAxisBreakdown?.yAxisBreakdownKeys.length);
// };

const processChartData = (response: string, item: any, widget: DashboardWidget, xAxisDataKey: XAxisDataKey) => {
  const { yAxisDataKeys, yAxisBreakdown, aggregation, tableKey } = widget;
  const { breakdownKey, yAxisBreakdownKeys, breakdownAggregation } = yAxisBreakdown ?? {};
  return yAxisDataKeys.reduce((result, yAxis) => {
    let count = 0;

    if (aggregation === 'sum') {
      count = isNaN(parseFloat(response)) ? 0 : parseFloat(response);
    } else {
      count = response === yAxis.key ? 1 : 0;
    }

    let subResult: any = {};
    if (aggregation !== 'sum' && yAxisBreakdownKeys?.length && breakdownKey) {
      // With Breakdown - will only apply if main aggregation !== 'sum'
      if (count === 1) {
        let breakdownResponse = item[breakdownKey];

        subResult = yAxisBreakdownKeys.reduce((breakdownResult, breakdown) => {
          let breakdownCount = 0;
          if (breakdownAggregation === 'sum') {
            breakdownCount = isNaN(parseFloat(breakdownResponse)) ? 0 : parseFloat(breakdownResponse);
          } else {
            breakdownCount = breakdownResponse === breakdown.key ? 1 : 0;
          }
          breakdownResult[breakdown.key] = breakdownCount;
          return breakdownResult;
        }, subResult);
      }
    } else {
      // Without Breakdown
      let yAxisKey = yAxis.displayName ?? yAxis.key;
      if (tableKey) {
        yAxisKey = tableKey.displayName ?? tableKey.key;
        if (xAxisDataKey.type !== 'number') {
          subResult[yAxisKey] = response;
        } else {
          subResult[yAxisKey] = count;
        }
      } else {
        subResult[yAxisKey] = count;
      }
    }

    return { ...result, ...subResult };
  }, {});
};

const combineResults = (arrayOfObjects: any[]) => {
  const result = arrayOfObjects.reduce((acc, obj) => {
    for (const key in obj) {
      if (acc.hasOwnProperty(key)) {
        acc[key] += obj[key];
      } else {
        acc[key] = obj[key];
      }
    }
    return acc;
  }, {});
  return result;
};
