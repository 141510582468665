import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Box, Typography, useTheme } from '@mui/material';

import React from 'react';
import { tokens } from 'context/theme.context';

interface DataItem {
  month: string;
  productA: number;
  productB: number;
  productC: number;
}

interface Props {
  title?: string;
  data: DataItem[];
  chartHeight?: number;
}

const BarGraph: React.FC<Props> = ({ title, data, chartHeight = 200 }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box>
      <Typography variant="h4" color={colors.text}>
        {title}
      </Typography>
      <ResponsiveContainer width="100%" height={chartHeight}>
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="Konsulta" stackId="a" fill="#8884d8" />
          <Bar dataKey="TB-DOTS" stackId="b" fill="#82ca9d" />
          <Bar dataKey="PCB 1" stackId="c" fill="#ffc658" />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default BarGraph;
