/* eslint-disable react-hooks/exhaustive-deps */

import {
  AreaChartComponent,
  CustomSelectField,
  DashboardTable,
  DashboardTable2,
  DashboardTable3,
  Header,
  PieChartComponent,
  ProtectedComponent,
  SimpleBarChart,
  SimpleBarGraph2,
  StackedBarGraph,
  TreeMapChartComponent,
} from 'components';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { useContext, useEffect } from 'react';

import { BreadcrumbContext } from 'context/breadcrumb.context';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EmailIcon from '@mui/icons-material/Email';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import StatBox from 'components/old/StatBox';
import TrafficIcon from '@mui/icons-material/Traffic';
import { getAssessmentBatches } from 'api/assessment-batch';
import healthData from '../../data/sample_data.json';
import { tokens } from '../../context/theme.context';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

// import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';

// import PowerBiDashboard from './PowerBiDashboard';

const Dashboard = () => {
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  let navigate = useNavigate();
  const [assessmentBatches, setAssessmentBatches] = useState([]);
  const [selectedBatch, setSelectedBatch] = useState('');
  const [responses, setResponses] = useState([]);
  const [filteredData, setFilteredData] = useState([]); //RHU

  const [filteredDataSBF, setFilteredDataSBF] = useState([]); //RHU

  const [filteredDoc, setFilteredDoc] = useState([]);
  const [sumDoctors, setSumDoctors] = useState(0);
  const [filteredNurse, setFilteredNurse] = useState([]);
  const [sumNurse, setSumNurse] = useState(0);
  const [konsultaSBF, setKonsultaSBF] = useState(0); //sbf
  const [konsultaRHU, setKonsultaRHU] = useState(0);
  const [konsultaInfi, setKonsultaInfi] = useState(0);
  const [konsultaBHS, setKonsultaBHS] = useState(0);
  //
  const [maSBF, setMaSBF] = useState(0); //sbf
  const [maRHU, setMaRHU] = useState(0);
  const [maInfi, setMaInfi] = useState(0);
  const [maBHS, setMaBHS] = useState(0);
  //
  const [newSBF, setNewSBF] = useState(0); //sbf
  const [newRHU, setNewRHU] = useState(0);
  const [newInfi, setNewInfi] = useState(0);
  const [newBHS, setNewBHS] = useState(0);
  //
  const [famSBF, setFamSBF] = useState(0); //sbf
  const [famRHU, setFamRHU] = useState(0);
  const [famInfi, setFamInfi] = useState(0);
  const [famBHS, setFamBHS] = useState(0);
  //
  const [population, setPopulation] = useState(0);
  const [household, setHousehold] = useState(0);
  const [fullTimeDoctors, setFullTimeDoctors] = useState(0);
  const [partTimeDoctors, setPartTimeDoctors] = useState(0);
  const [fullTimeNurse, setFullTimeNurse] = useState(0);
  const [partTimeNurse, setPartTimeNurse] = useState(0);
  const [fullTimeMidwife, setFullTimeMidwife] = useState(0);
  const [partTimeMidwife, setPartTimeMidwife] = useState(0);
  useEffect(() => {
    setResponses(healthData);

    const ruralHealthUnitData = responses.filter((facility) => facility['Facility Type'] === 'Rural Health Unit');
    const SbfData = responses.filter((facility) => facility['Facility Type'] === 'Safe Birthing Facility');
    const bhsData = responses.filter((facility) => facility['Facility Type'] === 'Barangay Health Station');
    const infirData = responses.filter((facility) => facility['Facility Type'] === 'Infirmary');

    if (Array.isArray(ruralHealthUnitData) && ruralHealthUnitData.length > 0) {
      const sumDoctors = ruralHealthUnitData.reduce(
        (sum, facility) =>
          sum +
          (parseInt(facility['Generalist Medical Doctors (Non-Specialist) - Full-time']) || 0) +
          (parseInt(facility['Generalist Medical Doctors (Non-Specialist) - Part-time']) || 0) +
          (parseInt(facility['Basic Emergency Obstetric and Neonatal Care (BEmONC) - Doctor']) || 0) +
          (parseInt(facility['Essential Intrapartum and Newborn Care (EINC) - Doctor']) || 0) +
          (parseInt(facility['Basic Life Support (BLS) - Doctor']) || 0),
        0
      );

      setFilteredDoc(sumDoctors);
      setSumDoctors(sumDoctors);

      // console.log('Filtered Data:', ruralHealthUnitData);
      // console.log('Sum of Doctors:', sumDoctors);
    }
    if (Array.isArray(ruralHealthUnitData) && ruralHealthUnitData.length > 0) {
      // Calculate and set values for each state
      const sumPopulation = ruralHealthUnitData.reduce(
        (sum, facility) => sum + (parseInt(facility['Population']) || 0),
        0
      );
      setPopulation(sumPopulation);

      const sumHousehold = ruralHealthUnitData.reduce(
        (sum, facility) => sum + (parseInt(facility['Household']) || 0),
        0
      );
      setHousehold(sumHousehold);

      const sumFullTimeDoctors = ruralHealthUnitData.reduce(
        (sum, facility) => sum + (parseInt(facility['Generalist Medical Doctors (Non-Specialist) - Full-time']) || 0),
        0
      );
      setFullTimeDoctors(sumFullTimeDoctors);

      const sumPartTimeDoctors = ruralHealthUnitData.reduce(
        (sum, facility) => sum + (parseInt(facility['Generalist Medical Doctors (Non-Specialist) - Part-time']) || 0),
        0
      );
      setPartTimeDoctors(sumPartTimeDoctors);

      const sumFullTimeNurse = ruralHealthUnitData.reduce(
        (sum, facility) => sum + (parseInt(facility['Registered Nurse - Full-time']) || 0),
        0
      );
      setFullTimeNurse(sumFullTimeNurse);

      const sumPartTimeNurse = ruralHealthUnitData.reduce(
        (sum, facility) => sum + (parseInt(facility['Registered Nurse - Part-time']) || 0),
        0
      );
      setPartTimeNurse(sumPartTimeNurse);

      const sumFullTimeMidwife = ruralHealthUnitData.reduce(
        (sum, facility) => sum + (parseInt(facility['Registered Midwife - Full-time']) || 0),
        0
      );
      setFullTimeMidwife(sumFullTimeMidwife);

      const sumPartTimeMidwife = ruralHealthUnitData.reduce(
        (sum, facility) => sum + (parseInt(facility['Registered Midwife - Part-time']) || 0),
        0
      );
      setPartTimeMidwife(sumPartTimeMidwife);
    }
    if (Array.isArray(ruralHealthUnitData) && ruralHealthUnitData.length > 0) {
      const sumNurse = ruralHealthUnitData.reduce(
        (sum, facility) =>
          sum +
          (parseInt(facility['Registered Nurse - Full-time']) || 0) +
          (parseInt(facility['Registered Nurse - Part-time']) || 0) +
          (parseInt(facility['Basic Emergency Obstetric and Neonatal Care (BEmONC) - Nurse']) || 0) +
          (parseInt(facility['Essential Intrapartum and Newborn Care (EINC) - Nurse']) || 0) +
          (parseInt(facility['Basic Life Support (BLS) - Nurse']) || 0),
        0
      );

      setFilteredData(ruralHealthUnitData);
      setFilteredNurse(sumNurse);
      setSumNurse(sumNurse);
      setFilteredDataSBF(SbfData);

      // console.log('Filtered Data:', ruralHealthUnitData);
      // console.log('Sum of Doctors:', sumDoctors);
    }

    //KONSULTA PACKAGE
    if (Array.isArray(SbfData) && SbfData.length > 0) {
      const konsultaYesFacilities = SbfData.filter((facility) => facility['Konsulta Package Provider'] === 'Yes');

      const countKonsultaYesFacilities = konsultaYesFacilities.length;

      const countAsInteger = parseInt(countKonsultaYesFacilities) || 0;
      setKonsultaSBF(countAsInteger);
    }
    if (Array.isArray(ruralHealthUnitData) && ruralHealthUnitData.length > 0) {
      const konsultaYesFacilities = ruralHealthUnitData.filter(
        (facility) => facility['Konsulta Package Provider'] === 'Yes'
      );

      const countKonsultaYesFacilities = konsultaYesFacilities.length;

      const countAsInteger = parseInt(countKonsultaYesFacilities) || 0;
      setKonsultaRHU(countAsInteger);
    }

    if (Array.isArray(bhsData) && bhsData.length > 0) {
      const konsultaYesFacilities = bhsData.filter((facility) => facility['Konsulta Package Provider'] === 'Yes');

      const countKonsultaYesFacilities = konsultaYesFacilities.length;

      const countAsInteger = parseInt(countKonsultaYesFacilities) || 0;
      setKonsultaBHS(countAsInteger);
    }

    if (Array.isArray(infirData) && infirData.length > 0) {
      const konsultaYesFacilities = infirData.filter((facility) => facility['Konsulta Package Provider'] === 'Yes');

      const countKonsultaYesFacilities = konsultaYesFacilities.length;

      const countAsInteger = parseInt(countKonsultaYesFacilities) || 0;
      setKonsultaInfi(countAsInteger);
    }

    //MATERNITY PACKAGE
    if (Array.isArray(SbfData) && SbfData.length > 0) {
      const konsultaYesFacilities = SbfData.filter((facility) => facility['Maternity Care Package'] === 'Yes');

      const countKonsultaYesFacilities = konsultaYesFacilities.length;

      const countAsInteger = parseInt(countKonsultaYesFacilities) || 0;
      setMaSBF(countAsInteger);
    }
    if (Array.isArray(ruralHealthUnitData) && ruralHealthUnitData.length > 0) {
      const konsultaYesFacilities = ruralHealthUnitData.filter(
        (facility) => facility['Maternity Care Package'] === 'Yes'
      );

      const countKonsultaYesFacilities = konsultaYesFacilities.length;

      const countAsInteger = parseInt(countKonsultaYesFacilities) || 0;
      setMaRHU(countAsInteger);
    }
    if (Array.isArray(bhsData) && bhsData.length > 0) {
      const konsultaYesFacilities = bhsData.filter((facility) => facility['Maternity Care Package'] === 'Yes');

      const countKonsultaYesFacilities = konsultaYesFacilities.length;

      const countAsInteger = parseInt(countKonsultaYesFacilities) || 0;
      setMaBHS(countAsInteger);
    }
    if (Array.isArray(infirData) && infirData.length > 0) {
      const konsultaYesFacilities = infirData.filter((facility) => facility['Maternity Care Package'] === 'Yes');

      const countKonsultaYesFacilities = konsultaYesFacilities.length;

      const countAsInteger = parseInt(countKonsultaYesFacilities) || 0;
      setMaInfi(countAsInteger);
    }

    //NEWBORN PACKAGE
    if (Array.isArray(SbfData) && SbfData.length > 0) {
      const konsultaYesFacilities = SbfData.filter((facility) => facility['Newborn Care Package'] === 'Yes');

      const countKonsultaYesFacilities = konsultaYesFacilities.length;

      const countAsInteger = parseInt(countKonsultaYesFacilities) || 0;
      setNewSBF(countAsInteger);
    }
    if (Array.isArray(ruralHealthUnitData) && ruralHealthUnitData.length > 0) {
      const konsultaYesFacilities = ruralHealthUnitData.filter(
        (facility) => facility['Newborn Care Package'] === 'Yes'
      );

      const countKonsultaYesFacilities = konsultaYesFacilities.length;

      const countAsInteger = parseInt(countKonsultaYesFacilities) || 0;
      setNewRHU(countAsInteger);
    }
    if (Array.isArray(bhsData) && bhsData.length > 0) {
      const konsultaYesFacilities = bhsData.filter((facility) => facility['Newborn Care Package'] === 'Yes');

      const countKonsultaYesFacilities = konsultaYesFacilities.length;

      const countAsInteger = parseInt(countKonsultaYesFacilities) || 0;
      setNewBHS(countAsInteger);
    }
    if (Array.isArray(infirData) && infirData.length > 0) {
      const konsultaYesFacilities = infirData.filter((facility) => facility['Newborn Care Package'] === 'Yes');

      const countKonsultaYesFacilities = konsultaYesFacilities.length;

      const countAsInteger = parseInt(countKonsultaYesFacilities) || 0;
      setNewInfi(countAsInteger);
    }

    //fAMILY PACKAGE
    if (Array.isArray(SbfData) && SbfData.length > 0) {
      const konsultaYesFacilities = SbfData.filter((facility) => facility['Family Planning Package'] === 'Yes');

      const countKonsultaYesFacilities = konsultaYesFacilities.length;

      const countAsInteger = parseInt(countKonsultaYesFacilities) || 0;
      setFamSBF(countAsInteger);
    }
    if (Array.isArray(ruralHealthUnitData) && ruralHealthUnitData.length > 0) {
      const konsultaYesFacilities = ruralHealthUnitData.filter(
        (facility) => facility['Family Planning Package'] === 'Yes'
      );

      const countKonsultaYesFacilities = konsultaYesFacilities.length;

      const countAsInteger = parseInt(countKonsultaYesFacilities) || 0;
      setFamRHU(countAsInteger);
    }
    if (Array.isArray(bhsData) && bhsData.length > 0) {
      const konsultaYesFacilities = bhsData.filter((facility) => facility['Family Planning Package'] === 'Yes');

      const countKonsultaYesFacilities = konsultaYesFacilities.length;

      const countAsInteger = parseInt(countKonsultaYesFacilities) || 0;
      setFamBHS(countAsInteger);
    }
    if (Array.isArray(infirData) && infirData.length > 0) {
      const konsultaYesFacilities = infirData.filter((facility) => facility['Family Planning Package'] === 'Yes');

      const countKonsultaYesFacilities = konsultaYesFacilities.length;

      const countAsInteger = parseInt(countKonsultaYesFacilities) || 0;
      setFamInfi(countAsInteger);
    }
  }, [responses]);

  const sumEINCDoctor = filteredData.reduce(
    (sum, facility) => sum + facility['Essential Intrapartum and Newborn Care (EINC) - Doctor'],
    0
  );

  const sumEINCNurse = filteredData.reduce(
    (sum, facility) => sum + facility['Essential Intrapartum and Newborn Care (EINC) - Nurse'],
    0
  );
  const sumBEONCDoctor = filteredData.reduce(
    (sum, facility) => sum + facility['Basic Emergency Obstetric and Neonatal Care (BEmONC) - Doctor'],
    0
  );
  const sumBEONCNurse = filteredData.reduce(
    (sum, facility) => sum + facility['Basic Emergency Obstetric and Neonatal Care (BEmONC) - Nurse'],
    0
  );
  const sumBLSDoctor = filteredData.reduce((sum, facility) => sum + facility['Basic Life Support (BLS) - Doctor'], 0);
  const sumBLSNurse = filteredData.reduce((sum, facility) => sum + facility['Basic Life Support (BLS) - Nurse'], 0);

  // Use useEffect to log responses when the component mounts
  // useEffect(() => {

  //   // Logging responses to console
  //   console.log('Responses:', responses);
  // }, [responses]); // Include responses in the dependency array if you want to log on each update

  // useEffect to load data on component mount

  useEffect(() => {
    setBreadcrumb([]);
  }, []);
  useEffect(() => {
    const fetchAssessmentBatches = async () => {
      try {
        const response = await getAssessmentBatches({ length: 1000 });
        if (response && response.data && response.data.rows) {
          setAssessmentBatches(response.data.rows);
          const savedBatch = localStorage.getItem('selectedBatch');

          if (savedBatch && response.data.rows.some((batch) => batch.value === savedBatch)) {
            setSelectedBatch(savedBatch);
          } else if (response.data.rows.length > 0) {
            setSelectedBatch(response.data.rows[0].value);
            localStorage.setItem('selectedBatch', response.data.rows[0].value);
          }
        }
      } catch (error) {
        console.error('Failed to fetch assessment batches:', error);
      }
    };

    fetchAssessmentBatches();
  }, []);
  const handleBatchChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedBatch(selectedValue);
    localStorage.setItem('selectedBatch', selectedValue);
  };

  const data = [
    { month: 'Facility A', Physicians: 450, Nurses: 320, 'Med Tech': 120 },
    { month: 'Facility B', Physicians: 370, Nurses: 250, 'Med Tech': 80 },
    { month: 'Facility C', Physicians: 420, Nurses: 180, 'Med Tech': 150 },
    { month: 'Facility D', Physicians: 340, Nurses: 290, 'Med Tech': 110 },
    { month: 'Facility E', Physicians: 520, Nurses: 410, 'Med Tech': 180 },
    { month: 'Facility F', Physicians: 480, Nurses: 350, 'Med Tech': 220 },
    { month: 'Facility G', Physicians: 390, Nurses: 290, 'Med Tech': 160 },
    { month: 'Facility H', Physicians: 420, Nurses: 310, 'Med Tech': 190 },
    { month: 'Facility I', Physicians: 470, Nurses: 380, 'Med Tech': 210 },
  ];

  const data2 = [
    { month: 'Aklan', Konsulta: 1200, 'TB-DOTS': 800, 'PCB 1': 600 },
    { month: 'Iloilo', Konsulta: 1500, 'TB-DOTS': 700, 'PCB 1': 800 },
    { month: 'Antique', Konsulta: 800, 'TB-DOTS': 1200, 'PCB 1': 500 },
    { month: 'Capiz', Konsulta: 1000, 'TB-DOTS': 900, 'PCB 1': 700 },
    { month: 'Guimaras', Konsulta: 500, 'TB-DOTS': 800, 'PCB 1': 800 },
    { month: 'Negros Occ', Konsulta: 1100, 'TB-DOTS': 400, 'PCB 1': 1000 },
  ];

  const data3 = [
    { name: 'Aklan', value: 400 },
    { name: 'Iloilo', value: 300 },
    { name: 'Antique', value: 200 },
    { name: 'Capiz', value: 100 },
    { name: 'Guimaras', value: 150 },
    { name: 'Negros Occ', value: 450 },
  ];

  // 'Compliant', 'Non-Compliant', 'Outsourced'
  const data4 = [
    { month: 'Safe Birthing Faciity', Compliant: 48, 'Non-Compliant': 52 },
    { month: 'Pharmacy', Compliant: 80, 'Non-Compliant': 12, Outsourced: 8 },
    { month: 'Radiologic services', Compliant: 63, 'Non-Compliant': 19, Outsourced: 18 },
    { month: 'Ambulance', Compliant: 23, 'Non-Compliant': 32, Outsourced: 45 },
  ];
  const data5 = [
    { month: 'Maternal Care ', Compliant: 30, 'Non-Compliant': 70 },
    { month: 'Oral Health', Compliant: 80, 'Non-Compliant': 20 },
    { month: 'Newborn, adolescent health', Compliant: 63, 'Non-Compliant': 37 },
  ];
  const data7 = [
    { month: 'Antenatal Care ', Compliant: 65, 'Non-Compliant': 35 },
    { month: 'Delivery Services', Compliant: 45, 'Non-Compliant': 55 },
    { month: 'Postnatal Care', Compliant: 63, 'Non-Compliant': 37 },
  ];
  const data6 = [
    {
      month: 'Municipality 1 ',
      'Safe Motherhood': 24,
      'Family Planning': 21,
      'Child Health': 20,
      Nutrition: 11,
      'Adolescent Health Development': 9,
      'Oral Health': 7,
      'Information, Communication and Technology / EMR': 5,
      'Other Program': 3,
    },
    {
      month: 'Municipality 2 ',
      'Safe Motherhood': 28,
      'Family Planning': 17,
      'Child Health': 25,
      Nutrition: 6,
      'Adolescent Health Development': 10.5,
      'Oral Health': 7,
      'Information, Communication and Technology / EMR': 5,
      'Other Program': 1.5,
    },
    {
      month: 'Municipality 3 ',
      'Safe Motherhood': 28,
      'Family Planning': 17,
      'Child Health': 5,
      Nutrition: 18,
      'Adolescent Health Development': 29,
      'Oral Health': 5,
      'Information, Communication and Technology / EMR': 5,
      'Other Program': 2,
    },
  ];
  const selectedBatchId = assessmentBatches.find((batch) => batch.batch_name === selectedBatch)?.indicator_id;

  const docRatio = Math.round(population / fullTimeDoctors + partTimeDoctors);
  const nurseRatio = Math.round(population / fullTimeNurse + partTimeNurse);
  const midRatio = Math.round(population / fullTimeMidwife + partTimeMidwife);
  console.log(docRatio);
  const tableData = [
    ['', 'Count', 'Ratio', 'Count', 'Ratio', 'Count', 'Ratio'],
    [
      `Sample RHU  Population: ${population} , Household:  ${household}`,
      `Full Time: ${fullTimeDoctors} , Part Time: ${partTimeDoctors}`,
      `1:${docRatio}`,
      `Full Time: ${fullTimeNurse} , Part Time: ${partTimeNurse}`,
      `1:${nurseRatio}`,
      `Full Time: ${fullTimeMidwife} , Part Time: ${partTimeMidwife}`,
      `1:${midRatio}`,
    ],
  ];
  const mockColumnData = [
    { title: 'Standard Per Health Workforce Per Rural Health Unit' },

    ' Generalist Medical Doctors (Non-Specialist)',
    'Registered Nurse',
    'Registered Midwife (full time and part time)',
  ];

  const combinedData = {
    tableData: tableData,
    mockColumnData: mockColumnData,
  };
  const percentageBEONCDoctor = ((sumBEONCDoctor / sumDoctors) * 100).toFixed(2) + '%';
  const percentageEINCDoctor = ((sumEINCDoctor / sumDoctors) * 100).toFixed(2) + '%';
  const percentageBLSDoctor = ((sumBLSDoctor / sumDoctors) * 100).toFixed(2) + '%';
  const tableData2 = [
    ['', 'Count', 'Percentage', 'Count', 'Percentage', 'Count', 'Percentage'],
    [
      'Sample RHU  Population',
      'Trainings Doctor: ' + sumBEONCDoctor.toString(),
      percentageBEONCDoctor,
      'Trainings Doctor: ' + sumEINCDoctor.toString(),
      percentageEINCDoctor,
      'Trainings Doctor: ' + sumBLSDoctor.toString(),
      percentageBLSDoctor,
    ],
  ];
  const mockColumnData2 = [
    { title: 'Healthcare Personnel Training - Doctor' },
    'Basic Emergency Obstetric and Neonatal Care (BEmONC)',
    'Essential Intrapartum and Newborn Care (EINC)',
    'Basic Life Support (BLS)',
  ];

  const combinedData2 = {
    tableData: tableData2,
    mockColumnData: mockColumnData2,
  };
  const percentageBEONCNurse = ((sumBEONCNurse / sumNurse) * 100).toFixed(2) + '%';
  const percentageEINCNurse = ((sumEINCNurse / sumNurse) * 100).toFixed(2) + '%';
  const percentageBLSNurse = ((sumBLSNurse / sumNurse) * 100).toFixed(2) + '%';
  const tableDataNew = [
    ['', 'Count', 'Percentage', 'Count', 'Percentage', 'Count', 'Percentage'],
    [
      'Sample RHU  Population',
      'Trainings Nurse: ' + sumBEONCNurse.toString(),
      percentageBEONCNurse,
      'Trainings Nurse: ' + sumEINCNurse.toString(),
      percentageEINCNurse,
      'Trainings Nurse: ' + sumBLSNurse.toString(),
      percentageBLSNurse,
    ],
  ];
  const mockColumnDataNew = [
    { title: 'Healthcare Personnel Training - Nurse' },
    'Basic Emergency Obstetric and Neonatal Care (BEmONC)',
    'Essential Intrapartum and Newborn Care (EINC)',
    'Basic Life Support (BLS)',
  ];

  const combinedDataNew = {
    tableData: tableDataNew,
    mockColumnData: mockColumnDataNew,
  };

  const tableData3 = [
    ['', 'Count', 'Percentage', 'Count', 'Percentage', 'Count', 'Percentage'],
    ['RHU 1', '4', '21.05%', '19', '100%', '18', '94.74%'],
    ['RHU 2', '10', '12.05%', '5', '6.02%', '73', '87.95%'],
    ['BHS Pinamuk-an (New Washington)', '2', '100%', '0', '100%', '1', '0%'],
  ];

  const mockColumnData3 = [{ title: 'Medicines per Services' }, 'RHU 1', 'RHU 2', 'BHS Pinamuk-an (New Washington)'];

  const combinedData3 = {
    tableData: tableData3,
    mockColumnData: mockColumnData3,
  };

  const chartData = [
    {
      name: 'Konsulta Package',
      'Safe Birthing Facility': konsultaSBF,
      'Rural Health Unit': konsultaRHU,
      Infirmary: konsultaInfi,
      'Barangay Health Station': konsultaBHS,
    },
    {
      name: 'Maternity Care Package',
      'Safe Birthing Facility': maSBF,
      'Rural Health Unit': maRHU,
      Infirmary: maInfi,
      'Barangay Health Station': maBHS,
    },
    {
      name: 'Newborn Package',
      'Safe Birthing Facility': newSBF,
      Infirmary: newInfi,
      'Barangay Health Station': newBHS,
      'Rural Health Unit': newRHU,
    },
    {
      name: 'Family Package',
      'Safe Birthing Facility': famSBF,
      Infirmary: famInfi,
      'Barangay Health Station': famBHS,
      'Rural Health Unit': famRHU,
    },
  ];
  const dataKeys = ['Safe Birthing Facility', 'Infirmary', 'Rural Health Unit', 'Barangay Health Station'];

  const chartColors = {
    'Safe Birthing Facility': '#0088FE', // color for 'sbf'
    'Rural Health Unit': '#00C49F', // color for 'rhs'
    'Barangay Health Station': '#FFBB28', // color for 'bhs'
    Infirmary: 'green',
  };

  const chartData2 = [
    {
      name: 'rhu1',
      rhu1: 65,
    },
    {
      name: 'rhu',
      rhu2: 85,
    },
    {
      name: 'rhu3',
      rhu3: 80,
    },
    {
      name: 'rhu4',
      rhu4: 100,
    },
    {
      name: 'rhu5',
      rhu5: 60,
    },
    {
      name: 'rhu6',
      rhu6: 85,
    },
    {
      name: 'rhu7',
      rhu7: 20,
    },
    {
      name: 'rhu8',
      rhu8: 95,
    },
    {
      name: 'rhu9',
      rhu9: 100,
    },
    {
      name: 'rhu10',
      rhu10: 100,
    },
    {
      name: 'rhu11',
      rhu11: 100,
    },
    {
      name: 'rhu12',
      rhu12: 100,
    },
  ];
  const dataKeys2 = ['rhu1', 'rhu2', 'rhu3', 'rhu4', 'rhu5', 'rhu6', 'rhu7', 'rhu8', 'rhu9', 'rhu10', 'rhu11', 'rhu12'];

  const chartColors2 = {
    rhu1: '#0088FE', // color for 'sbf'
    rhu2: '#00C49F', // color for 'rhs'
    rhu3: '#FFBB28', // color for 'bhs'
    rhu4: '#FF8042',
    rhu5: '#0088FE', // color for 'sbf'
    rhu6: '#00C49F', // color for 'rhs'
    rhu7: '#FFBB28', // color for 'bhs'
    rhu8: '#FF8042',
    rhu9: '#0088FE', // color for 'sbf'
    rhu10: '#00C49F', // color for 'rhs'
    rhu11: '#FFBB28', // color for 'bhs'
    rhu12: '#FF8042',
  };
  const dataz = {
    header1: 'EMR INDICATORS',
    header2: 'Count',
    header3: 'Total',
    header4: 'Percentage',

    rows: [
      {
        col1: 'RRecords are stored, retained and disposed of in accordance with the guidelines set by National Archives of the Philippines (NAP)',
        col2: '202',
        col3: '250',
        col4: '80.80%',
      },
      {
        col1: 'Facility has logbooks on record storage, retention, and disposal',
        col2: '246',
        col3: '250',
        col4: '98.40%',
      },
      { col1: 'Facility has proper storage of records ', col2: '158', col3: '250', col4: '63.20%' },
      {
        col1: 'The facility 1) defines data sets, data generation, collection and aggregation methods, 2) determines qualified staff who are involved in each stage, and 3) has policies and procedures on record storage, safekeeping and maintenance, retention, and disposal ',
        col2: '98',
        col3: '250',
        col4: '39.20%',
      },
      {
        col1: 'Patient records documenting any previous care can be retrieved within 10–15 minutes for review, updating and concurrent use. ',
        col2: '232',
        col3: '250',
        col4: '92.80%',
      },
      {
        col1: 'The organization has policies and procedures, and devotes resources, including infrastructure, to protect records and patient charts against loss, destruction, tampering and unauthorized access or use. Only authorized individuals make entries in the patient records ',
        col2: '142',
        col3: '250',
        col4: '56.80%',
      },
      {
        col1: 'Facility has logbooks for borrowing and retrieval of records ',
        col2: '50',
        col3: '250',
        col4: '20.00%',
      },
      { col1: 'Records can be accessed ', col2: '225', col3: '250', col4: '90%' },
    ],
  };
  return (
    <Box>
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />

        <Box display="flex" gap="20px" alignItems="flex-end">
          <Box width="250px">
            <CustomSelectField
              label="Assessment Batch"
              fieldName="assessment_batch"
              value={selectedBatch}
              handleChange={handleBatchChange}
              options={assessmentBatches.map((batch) => ({
                key: batch.batch_name, // or the correct field name from the API response
                value: batch.batch_name, // same here
              }))}
            />
          </Box>

          <Box flex="1">
            <ProtectedComponent requiredAuth={['MANAGE_DASHBOARD']}>
              <Button
                sx={{
                  backgroundColor: colors.secondary,
                  color: colors.text,
                  fontSize: '14px',
                  fontWeight: 'bold',
                  padding: '14.5px 20px',
                  // width: '180px',
                }}
                onClick={() => {
                  if (selectedBatchId) {
                    navigate(`/dashboard-editor/${selectedBatchId}`);
                  } else {
                    console.warn('No batch ID found for selected batch');
                  }
                }}
              >
                <EditOutlinedIcon sx={{ mr: '10px' }} />
                Edit Dashboard
              </Button>
            </ProtectedComponent>
          </Box>

          {/* <Box flex="1">
            <Button
              sx={{
                backgroundColor: colors.secondary,
                color: colors.text,
                fontSize: '14px',
                fontWeight: 'bold',
                padding: '14.5px 20px',
                width: '220px',
              }}
            >
              <DownloadOutlinedIcon sx={{ mr: '10px' }} />
              Download Reports
            </Button>
          </Box> */}
        </Box>
      </Box>
      {/* GRID & CHARTS */}
      <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gridAutoRows="140px" gap="20px">
        {/* ROW 1 */}
        <Box
          gridColumn="span 3"
          backgroundColor={colors.secondary_background}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="12,361"
            subtitle="Total Health Facilities"
            progress="0.75"
            increase="+14%"
            icon={<EmailIcon sx={{ color: colors.accent, fontSize: '26px' }} />}
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.secondary_background}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="431,225"
            subtitle="Total FRESAA Submitted"
            progress={0.8}
            increase="+21%"
            icon={<PointOfSaleIcon sx={{ color: colors.accent, fontSize: '26px' }} />}
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.secondary_background}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="32,441"
            subtitle="Graph 2"
            progress="0.30"
            increase="+5%"
            icon={<PersonAddIcon sx={{ color: colors.accent, fontSize: '26px' }} />}
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.secondary_background}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="1,325,134"
            subtitle="Graph 4"
            progress="0.80"
            increase="+43%"
            icon={<TrafficIcon sx={{ color: colors.accent, fontSize: '26px' }} />}
          />
        </Box>
        {/* 
        <Box
          gridColumn="span 12"
          gridRow="span 4"
          backgroundColor={colors.secondary_background}
        >
          <PowerBiDashboard />
        </Box> */}

        {/* ROW 2 */}
        <Box gridColumn="span 8" gridRow="span 2" backgroundColor={colors.secondary_background}>
          <Box mt="25px" p="0 30px" display="flex " justifyContent="space-between" alignItems="center">
            <Box>
              <Typography variant="h5" fontWeight="600" color={colors.text}>
                Accredited PHIC packages available in the health facilities
              </Typography>
            </Box>
          </Box>
          <Box height="250px" m="20px 20px 20px 5px">
            <StackedBarGraph
              data={data2}
              dataKeys={['Konsulta', 'TB-DOTS', 'PCB 1']}
              barColors={['#8884d8', '#82ca9d', '#ffc658']}
            />
          </Box>

          <Box height="250px" m="20px 20px 20px 5px"></Box>
        </Box>
        <Box gridColumn="span 4" gridRow="span 2" backgroundColor={colors.secondary_background}>
          <Typography variant="h5" fontWeight="600" sx={{ padding: '30px 30px 0 30px' }}>
            Health Personnel
          </Typography>
          <Box height="250px" m="20px 20px 20px 5px">
            <AreaChartComponent data={data.slice(0, 4)} />
          </Box>
        </Box>

        {/* ROW 3 */}
        <Box gridColumn="span 4" gridRow="span 2" backgroundColor={colors.secondary_background} p="30px">
          <Typography variant="h5" fontWeight="600">
            RHUs Linked to a Hospital (with MOA)
          </Typography>
          <Box height="250px" m="20px 20px 20px 5px">
            <TreeMapChartComponent data={data3} />
          </Box>
        </Box>

        <Box gridColumn="span 4" gridRow="span 2" backgroundColor={colors.secondary_background}>
          <Typography variant="h5" fontWeight="600" sx={{ padding: '30px 30px 0 30px' }}>
            Licensed Services
          </Typography>
          <Box height="250px" m="20px 20px 20px 0px">
            <StackedBarGraph
              data={data2}
              dataKeys={['Konsulta', 'TB-DOTS', 'PCB 1']}
              barColors={['#8884d8', '#82ca9d', '#ffc658']}
            />
          </Box>
        </Box>

        <Box gridColumn="span 4" gridRow="span 2" backgroundColor={colors.secondary_background} padding="30px">
          <Typography variant="h5" fontWeight="600" sx={{ marginBottom: '15px' }}>
            Number of Facilities
          </Typography>
          <Box height="250px" m="20px 20px 20px 0px">
            <PieChartComponent data={data3} />
          </Box>
        </Box>
        <Box gridColumn="span 6" gridRow="span 2" backgroundColor={colors.secondary_background} p="30px">
          <Typography variant="h5" fontWeight="600">
            PHILHEALTH ACCREDITATION
          </Typography>
          <Box height="250px" m="20px 20px 20px 5px">
            <SimpleBarChart data={chartData} colors={chartColors} dataKeys={dataKeys} />
          </Box>
        </Box>
        <Box gridColumn="span 6" gridRow="span 2" backgroundColor={colors.secondary_background} p="30px">
          <Typography variant="h5" fontWeight="600">
            Outcome indicators for Mortality and Nutrition
          </Typography>
          <Box height="250px" m="20px 20px 20px 5px">
            <SimpleBarGraph2 />
          </Box>
        </Box>
        <Box gridColumn="span 6" gridRow="span 2" backgroundColor={colors.secondary_background} p="30px">
          <Typography variant="h5" fontWeight="600">
            PCF Chart (PCF Compliance per Rural Health Unit)
          </Typography>
          <Box height="250px" m="20px 20px 20px 5px">
            <SimpleBarChart data={chartData2} colors={chartColors2} dataKeys={dataKeys2} />
          </Box>
        </Box>
        <Box gridColumn="span 6" gridRow="span 2" backgroundColor={colors.secondary_background} p="30px">
          <Typography variant="h5" fontWeight="600">
            Budget for health
          </Typography>
          <Box height="250px" m="20px 20px 20px 5px">
            <StackedBarGraph
              data={data6}
              dataKeys={[
                'Other Program',
                'Information, Communication and Technology / EMR',
                'Oral Health',
                'Adolescent Health Development',
                'Nutrition',
                'Child Health',
                'Family Planning',
                'Safe Motherhood',
              ]}
              barColors={['grey', 'orange', 'blue', 'purple', 'green', 'blue', 'yellow', 'red']}
            />
          </Box>
        </Box>
        <Box gridColumn="span 4" gridRow="span 2" backgroundColor={colors.secondary_background} p="30px">
          <Typography variant="h5" fontWeight="600">
            Chart 8 (DOH Licenses)
          </Typography>
          <Box height="250px" m="20px 20px 20px 5px">
            <StackedBarGraph
              data={data4}
              dataKeys={['Outsourced', 'Non-Compliant', 'Compliant']}
              barColors={['grey', 'green', 'red']}
            />
          </Box>
        </Box>
        <Box gridColumn="span 4" gridRow="span 2" backgroundColor={colors.secondary_background} p="30px">
          <Typography variant="h5" fontWeight="600">
            Chart 7A (Presence of DOH policies in RHUs)
          </Typography>
          <Box height="250px" m="20px 20px 20px 5px">
            <StackedBarGraph data={data5} dataKeys={['Non-Compliant', 'Compliant']} barColors={['green', 'red']} />
          </Box>
        </Box>
        <Box gridColumn="span 4" gridRow="span 2" backgroundColor={colors.secondary_background} p="30px">
          <Typography variant="h5" fontWeight="600">
            Chart 2 (Service capability of RHUs)
          </Typography>
          <Box height="250px" m="20px 20px 20px 5px">
            <StackedBarGraph data={data7} dataKeys={['Non-Compliant', 'Compliant']} barColors={['green', 'red']} />
          </Box>
        </Box>

        <Box gridColumn="span 12">
          <DashboardTable combinedData={combinedData} />
          <DashboardTable combinedData={combinedData2} />
          <DashboardTable combinedData={combinedDataNew} />
          <DashboardTable2 combinedData={combinedData3} />
          <DashboardTable3 tableData={dataz} />;
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
