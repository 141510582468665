import { Draggable } from 'react-beautiful-dnd';
import { PieChartComponent } from 'components';
import React from 'react';
import { styled } from '../stiches.config';
import { tokens } from 'context/theme.context';
import { useTheme } from '@mui/material';
interface ItemProps {
  text: string;
  index: number;
}

const Item: React.FC<ItemProps> = ({ text, index }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const data3 = [
    { name: 'Aklan', value: 400 },
    { name: 'Iloilo', value: 300 },
    { name: 'Antique', value: 200 },
    { name: 'Capiz', value: 100 },
    { name: 'Guimaras', value: 150 },
    { name: 'Negros Occ', value: 450 },
  ];

  const StyledItem = styled('div', {
    backgroundColor: colors.secondary_background,
    borderRadius: 8,
    padding: '4px 8px',
    transition: 'background-color .8s ease-out',
    // border: '1px solid #149940',

    ':hover': {
      backgroundColor: '#fff',
      transition: 'background-color .1s ease-in',
    },
  });

  return (
    <Draggable draggableId={text} index={index}>
      {(provided, snapshot) => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <StyledItem>
            <PieChartComponent title={text} data={data3} />
          </StyledItem>
        </div>
      )}
    </Draggable>
  );
};

export default Item;
