/* eslint-disable react-hooks/exhaustive-deps */

import { Box, useTheme } from '@mui/material';
import { DashboardWidget, getDashboardData2 } from './charts/chartDataProcessor';
import { Header, RegularButton } from 'components';
import { useContext, useEffect, useMemo } from 'react';

import { AklanDashboard2 } from './charts/chartConfiguration2';
import { BreadcrumbContext } from 'context/breadcrumb.context';
import DashboardBarChart from './charts/DashboardBarChart';
import DashboardTable from './charts/DashboardTable';
import { getAssessmentBatches } from 'api/assessment-batch';
import healthData from '../../data/sample_data_aklan.json';
import { tokens } from '../../context/theme.context';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

// import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';

// import PowerBiDashboard from './PowerBiDashboard';

const Home = () => {
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const theme = useTheme();
  const navigate = useNavigate();

  const colors = tokens(theme.palette.mode);
  const [assessmentBatches, setAssessmentBatches] = useState<any[]>([]);
  const [selectedBatch, setSelectedBatch] = useState('');
  const [widgets, setWidgets] = useState<DashboardWidget[]>(AklanDashboard2);

  // Use useEffect to log responses when the component mounts
  // useEffect(() => {

  //   // Logging responses to console
  //   console.log('Responses:', responses);
  // }, [responses]); // Include responses in the dependency array if you want to log on each update

  // useEffect to load data on component mount

  useEffect(() => {
    setBreadcrumb([]);
  }, []);

  useEffect(() => {
    const fetchAssessmentBatches = async () => {
      try {
        const response = await getAssessmentBatches({ length: 1000 });
        if (response && response.data && response.data.rows) {
          setAssessmentBatches(response.data.rows);
          const savedBatch = localStorage.getItem('selectedBatch');

          if (savedBatch && response.data.rows.some((batch: any) => batch.value === savedBatch)) {
            setSelectedBatch(savedBatch);
          } else if (response.data.rows.length > 0) {
            setSelectedBatch(response.data.rows[0].value);
            localStorage.setItem('selectedBatch', response.data.rows[0].value);
          }
        }
      } catch (error) {
        console.error('Failed to fetch assessment batches:', error);
      }
    };

    fetchAssessmentBatches();
  }, []);

  const handleBatchChange = (e: any) => {
    const selectedValue = e.target.value;
    setSelectedBatch(selectedValue);
    localStorage.setItem('selectedBatch', selectedValue);
  };

  const chart = useMemo(() => {
    return (
      <>
        {widgets.map((chart, index) => {
          // const { data, chartColors, dataKeys } = getDashboardData(healthData, chart);
          const { data, chartColors, dataKeys } = getDashboardData2(healthData, chart);
          switch (chart.type) {
            case 'bar':
              return (
                <DashboardBarChart
                  key={index}
                  title={chart.title}
                  data={data}
                  chartColors={chartColors}
                  dataKeys={dataKeys}
                  colSpan={chart.containerDimnesion?.colSpan}
                  rowSpan={chart.containerDimnesion?.rowSpan}
                  xAxisLabel={chart.label.xAxisLabel}
                  yAxisLabel={chart.label.yAxisLabel}
                  stacked={chart.stacked}
                  displayAsPercentage={chart.displayAsPercentage}
                  tickFormatter={chart.displayAsPercentage ? (tick) => `${tick}%` : undefined}
                  // conditionalBarColorGetter={chart.conditionalBarColorGetter}
                  // hideLegend={chart.hideLegend}
                />
              );
            case 'table':
              return (
                <DashboardTable
                  key={index}
                  title={chart.title}
                  tableKey={chart.tableKey!.displayName ?? chart.tableKey!.key}
                  data={data}
                  columns={chart.xAxisDataKeys}
                  colSpan={chart.containerDimnesion?.colSpan}
                  rowSpan={chart.containerDimnesion?.rowSpan}
                  visibleColumns={chart.tableSettings?.visibleColumns}
                  // colSpan={chart.colSpan}
                  // rowSpan={chart.rowSpan}
                />
              );
          }
          return <></>;
        })}
      </>
    );
  }, [widgets]);

  return (
    <Box>
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />
        <RegularButton label="Aklan PCF Table" onClick={() => navigate('/test-table')} />
      </Box>
      {/* GRID & CHARTS */}
      <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gridAutoRows="180px" gap="20px">
        {/* ROW 1 */}
        {chart}
      </Box>
    </Box>
  );
};

export default Home;
