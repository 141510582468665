import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Label,
  Legend,
  ResponsiveContainer,
  Text,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Box, Typography, useTheme } from '@mui/material';

import { Formatter } from 'recharts/types/component/DefaultLegendContent';
// Import necessary libraries
import React from 'react';
import { formatNumber } from 'utils';
import { relative } from 'path';
import { tokens } from 'context/theme.context';

// Add an index signature to the DefaultColors type

// Define the SimpleBarChart component with data as a prop

export interface ChartData {
  name: any;
  [key: string]: any;
}

interface DashboardBarChartProps {
  data: any[];
  title: string;
  xAxisLabel?: string;
  xAxisLabelDiagonal?: boolean;
  yAxisLabel?: string;
  chartColors: any;
  dataKeys: string[]; // Add dataKeys as a prop
  colSpan?: number;
  rowSpan?: number;
  stacked?: boolean;
  displayAsPercentage?: boolean;
  hideLegend?: boolean;
  tickFormatter?: (value: any, index: number) => string;
  conditionalBarColorGetter?: (data: any) => string;
}

const DashboardBarChart: React.FC<DashboardBarChartProps> = ({
  title,
  xAxisLabel,
  xAxisLabelDiagonal,
  yAxisLabel,
  data,
  chartColors,
  dataKeys,
  colSpan = 6,
  rowSpan = 2,
  stacked,
  tickFormatter,
  displayAsPercentage,
  conditionalBarColorGetter,
  hideLegend,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const defaultColors: any = tokens(theme.palette.mode);

  const getColor = (item: any, key: string) => {
    if (conditionalBarColorGetter) {
      return conditionalBarColorGetter(item.extra[key]);
    }
    return chartColors[key] || defaultColors[key];
  };

  const tooltipFormatter = (value: any, name: any, item: any) => {
    if (displayAsPercentage && item.payload.extra) {
      const data = item.payload.extra;
      const total = data.total ?? 0;
      const count = data[name] ?? 0;
      return displayAsPercentage ? `${value}% (${count} / ${total})` : value;
    }
    return value;
  };

  const CustomXAxisTick = ({ x, y, payload }: any) => {
    if (payload && payload.value) {
      return (
        <Text fontSize={'12px'} width={90} x={x} y={y} textAnchor="middle" verticalAnchor="start">
          {payload.value}
        </Text>
      );
    }
    return null;
  };

  return (
    <Box
      gridColumn={'span ' + colSpan}
      gridRow={'span ' + rowSpan}
      p="30px"
      sx={{ backgroundColor: colors.secondary_background }}
      borderRadius={2}
    >
      <Typography variant="h5" fontWeight="600">
        {title}
      </Typography>
      <Box height="100%" paddingY="20px">
        <ResponsiveContainer width="100%">
          <BarChart
            width={500}
            data={data}
            barGap={10}
            barCategoryGap={20}
            margin={{ top: 0, right: 30, left: 20, bottom: 35 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis tick={<CustomXAxisTick />} dataKey="chart-key" interval={0}>
              <Label value={xAxisLabel} position="center" dy={40} height={50} />
            </XAxis>

            <YAxis tickFormatter={tickFormatter}>
              <Label value={yAxisLabel} position="insideBottomLeft" angle={-90} />
            </YAxis>

            <Tooltip
              formatter={(value, name, item) => {
                return tooltipFormatter && tooltipFormatter(value, name, item);
              }}
              labelStyle={{ fontWeight: 'bold', fontSize: '16px' }}
              contentStyle={{
                borderRadius: '10px',
                padding: '15px 20px',
                backgroundColor: colors.solidHoverBackground,
                border: 'none',
              }}
            />
            {!hideLegend && <Legend verticalAlign="top" wrapperStyle={{ paddingBottom: '20px' }} align="center" />}

            {dataKeys?.map((key, index) => (
              <Bar
                barSize={40}
                key={index}
                dataKey={key}
                fill={chartColors[key] || defaultColors[key]}
                stackId={stacked ? 'stack-id' : undefined}
              >
                {data?.map((item, index) => (
                  <Cell key={`cell_${index}`} fill={getColor(item, key)} />
                ))}
              </Bar>
            ))}
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

export default DashboardBarChart;
