import { Box, Typography, useTheme } from '@mui/material';
import { ResponsiveContainer, Tooltip, Treemap } from 'recharts';

import React from 'react';
import { tokens } from 'context/theme.context';

interface DataItem {
  name: string;
  value: number;
}

interface Props {
  title?: string;
  data: DataItem[];
  chartHeight?: number;
}

const TreeMapChartComponent: React.FC<Props> = ({ title, data, chartHeight = 200 }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box>
      <Typography variant="h4" color={colors.text}>
        {title}
      </Typography>
      <ResponsiveContainer width="100%" height={chartHeight}>
        <Treemap width={800} height={400} data={data} dataKey="value" aspectRatio={4 / 3} stroke="#fff" fill="#8884d8">
          <Tooltip />
        </Treemap>
      </ResponsiveContainer>
    </Box>
  );
};

export default TreeMapChartComponent;
