import { Box, Typography, useTheme } from '@mui/material';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { tokens } from 'context/theme.context';

export type DataKeysProps = {
  dataKey: string;
  stroke: string;
};

type CustomContainerProps = {
  title?: string;
  data: any;
  dataKeys: DataKeysProps[];
  chartHeight?: number;
  xAxisKey: string;
  yAxisLabel: string;
};

const CustomLineChart: React.FC<CustomContainerProps> = ({ title, data, chartHeight = 200 }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box>
      <Typography variant="h4" color={colors.text}>
        {title}
      </Typography>
      <ResponsiveContainer width="100%" height={chartHeight}>
        <LineChart width={800} height={400} data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="value1" stroke="#8884d8" activeDot={{ r: 8 }} />
          <Line type="monotone" dataKey="value2" stroke="#82ca9d" activeDot={{ r: 8 }} />
          <Line type="monotone" dataKey="value3" stroke="#ffc658" activeDot={{ r: 8 }} />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default CustomLineChart;
