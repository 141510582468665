import { Box, Typography, useTheme } from '@mui/material';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';

import React from 'react';
import { tokens } from 'context/theme.context';

interface DataItem {
  name: string;
  value: number;
}

interface Props {
  title?: string;
  data: DataItem[];
  chartHeight?: number;
}

const COLORS = ['#8884d8', '#82ca9d', '#ffc658', '#8dd1e1', '#a4de6c'];

const PieChartComponent: React.FC<Props> = ({ title, data, chartHeight = 200 }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box>
      <Typography variant="h4" color={colors.text}>
        {title}
      </Typography>
      <ResponsiveContainer width="100%" height={chartHeight}>
        <PieChart>
          <Pie
            data={data}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            outerRadius={70}
            fill="#8884d8"
            labelLine={false}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default PieChartComponent;
