import { Area, AreaChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Box, Typography, useTheme } from '@mui/material';

import React from 'react';
import { tokens } from 'context/theme.context';

interface DataItem {
  month: string;
  value1: number;
  value2: number;
  value3: number;
}

interface Props {
  title: string;
  data: DataItem[];
  chartHeight?: number;
}

const AreaChartComponent: React.FC<Props> = ({ title, data, chartHeight = 200 }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box>
      <Typography variant="h4" color={colors.text}>
        {title}
      </Typography>
      <ResponsiveContainer width="100%" height={chartHeight}>
        <AreaChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Area type="monotone" dataKey="Physicians" stroke="#8884d8" fill="#8884d8" />
          <Area type="monotone" dataKey="Nurses" stroke="#82ca9d" fill="#82ca9d" />
          <Area type="monotone" dataKey="Med Tech" stroke="#ffc658" fill="#ffc658" />
        </AreaChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default AreaChartComponent;
