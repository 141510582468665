// Import necessary libraries
import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Box, Typography, useTheme } from '@mui/material';
import { tokens } from 'context/theme.context';

// Add an index signature to the DefaultColors type
type DefaultColors = {
  [key: string]: string;
  primary: string;
  secondary: string;
  accent: string;
  // Add other colors here
};

// Define the SimpleBarChart component with data as a prop
interface SimpleBarChartProps {
  data: {
    name: any;
    [key: string]: number;
  }[];
  title?: string;
  chartHeight?: number;
  colors?: DefaultColors;
  dataKeys?: string[]; // Add dataKeys as a prop
}

const SimpleBarChart: React.FC<SimpleBarChartProps> = ({ title, data, chartHeight = 200, colors, dataKeys }) => {
  const theme = useTheme();
  const defaultColors: DefaultColors = tokens(theme.palette.mode);

  return (
    <Box>
      <Typography variant="h4" color={defaultColors.text}>
        {title}
      </Typography>
      <ResponsiveContainer width="100%" height={chartHeight}>
        <BarChart
          width={500}
          height={300}
          data={data}
          // margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          barGap={0}
          barCategoryGap={20}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            interval={0}
            angle={dataKeys && dataKeys.length > 10 ? -45 : 0}
            textAnchor={dataKeys && dataKeys.length > 10 ? 'end' : 'middle'}
          />
          <YAxis />
          <Tooltip />
          <Legend />

          {/* Map through dataKeys and render Bar components */}
          {dataKeys?.map((key, index) => (
            <Bar barSize={30} key={index} dataKey={key} fill={colors?.[key] || defaultColors[key]} />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default SimpleBarChart;
