import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const data = [
  {
    name: 'Group 1',
    leftValue1: 80,
    leftValue2: 280,
    leftValue3: 150,
    leftValue4: 180,
  },
  {
    name: 'Group 2',
    rightValue1: 8000,
    rightValue2: 4500,
    rightValue3: 11000,
  },
  // Add more data points as needed
];

const SimpleBarGraph2 = () => {
  return (
    <BarChart
      width={500}
      height={200}
      data={data}
      margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      barGap={0}
      barCategoryGap={30} // Adjust the bar category gap as needed to center the groups
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis yAxisId="left" orientation="left" domain={[0, 400]} />
      <YAxis yAxisId="right" orientation="right" domain={[0, 12000]} />
      <Tooltip />
      <Legend />
      <Bar dataKey="leftValue1" fill="#cc2009" yAxisId="left" barSize={30} />
      <Bar dataKey="leftValue2" fill="#d87b64" yAxisId="left" barSize={30} />
      <Bar dataKey="leftValue3" fill="#cc2009" yAxisId="left" barSize={30} />
      <Bar dataKey="leftValue4" fill="#610909" yAxisId="left" barSize={30} />
      <Bar dataKey="rightValue1" fill="#20b452" yAxisId="right" barSize={30} />
      <Bar dataKey="rightValue2" fill="#82ca9d" yAxisId="right" barSize={30} />
      <Bar dataKey="rightValue3" fill="#0a6d1b" yAxisId="right" barSize={30} />
    </BarChart>
  );
};

export default SimpleBarGraph2;
